import React from 'react';
import Icon, { RESOURCE } from '@/components/Icon';
import { useSelector } from 'react-redux';
import { END } from 'redux-saga'
import { wrapper } from '../store'
import { actions as mainActions } from '../reducers/main';
import Seo from '../components/Seo';

const Index = () => {
  const currentSeo = useSelector(state => state.main.currentSeo);
  return (
    <>
      <Seo {...currentSeo} />
      <section className='content'>
          <div className="rounded-logo">
            <div className="video-gap">
              <video  className="logovic" muted loop={true} autoPlay={true}>
                <source src="/animations/vicv.webm" type="video/webm"/>
                <source src="/animations/vicv.mp4" type="video/mp4"/>
              </video>
            </div>
          </div>
          <h2>¡¡ Estamos en construcción !!</h2>
          <p>Mientras tanto puedes acceder a:</p>
          <div className='rssgap'>
            <a
              title="Perfil linkedIn Vicdesign"
              href="https://www.linkedin.com/in/vicdesign/"
              alt="Perfil linkedIn Vicdesign"
            >
              <Icon
                resource={RESOURCE.LINKEDIN}
                fillColor="white"
                size={{
                  w: 'auto',
                  h: 30,
                }}
              />
            </a>
            <a
              title="Desarrollo web VICDESIGN"
              href="https://www.facebook.com/VICDESIGN77"
              alt="Desarrollo web VICDESIGN"
            >
              <Icon
                resource={RESOURCE.FACEBOOK}
                fillColor="white"
                size={{
                  w: 'auto',
                  h: 30,
                }}
              />
            </a>
          </div>
        <style jsx>
          {`
            .content {
              display: flex;
              width: 100%;
              position:absolute;
              background-color: black;
              top: 0px;
              color:white;
              left:0px;
              height: 100vh;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .content .rssgap{
              margin-top: 1rem;
            }
            .content .rounded-logo{
                position: relative;
                background-color: white;
                border-radius: 100%;
                width: 7rem;
                height: 7rem;
                display:block;
                margin: 1rem auto;
            }
            .content .rounded-logo .video-gap{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80%;
              height: auto;
              overflow:hidden;
            }
            .content .rounded-logo .video-gap video {
              width: calc(100% + 5px);
              height:auto;
            }
          `}
        </style>
      </section>
    </>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(async ({ store, req, res }) => {
  store.dispatch(mainActions.getProjectsKind());
  store.dispatch(END);
  await store.sagaTask.toPromise();
});


export default Index
 