import dynamic from 'next/dynamic';
import React from 'react';

export const RESOURCE = {
    FACEBOOK: '/rrss/facebook.svg',
    LINKEDIN: '/rrss/linkedin.svg',
}

const Icon = (props) => {
    let { size } = props;
    if (size.w && !size.h) {
        size.h = 'auto'
    }
    if (size.h && !size.w) {
        size.w = 'auto'
    }

    const { resource } = props;
    const keys = Object.keys(RESOURCE);
    const alt = keys.find(key => RESOURCE[key] === resource) || "";


    return (
        <img style={{width: size.w, height: size.h}} src={resource} alt={alt} />
    )
}


export default Icon;
